import React from 'react'
import {Link} from 'react-router-dom'

import './css/effort-tile.css'
import { useMemo } from 'react'

const EffortTile = ({effort, top, left, width, height, pxPerDay}) => {
    const bestImage = useMemo(() => {
        let best = effort.project.heroImage?.url || "";
        if(!effort.entries) return best;
        for(let i = 0; i < effort.entries.length; i++) {
            if(effort.entries[i].images && effort.entries[i].images.length > 0) {
                best = effort.entries[i].images[0].url;
                return best;
            }
        }
        return best;
    }, [effort]);

    return (
        <Link to={`${process.env.PUBLIC_URL}/entries/${effort.entries.slice(-1)[0].slug}`}
            className={height < 24 ? "effort-tile effort-tile-small" : "effort-tile"} 
            style={{
                top: `${top}px`,
                right: `${left}%`,
                width: `calc(${width}% - 0.5em)`,
                height: `${height}px`,
                backgroundImage: `url('${bestImage}')`,
            }}
        >
            <div className="effort-tile-inner">
                {window.innerWidth < 1000 ? null : (
                <div>
                    <p>
                        {`${effort.project.name} -`}
                    </p>
                    <p>
                        {effort.summary}
                    </p>
                </div>
                )}
            </div>
        </Link>
    )
}

export default EffortTile;