import React, {useEffect, useContext, useState, useRef} from 'react'

import axios from 'axios'

import DataContext from "../../DataContext"
import WeeklogUtils from '../../shared/WeeklogUtils'

import FirebaseConfig from '../../FirebaseConfig'

import obscureSecrets from './obscureSecrets'
import { reindexProject, reindexResource, reindexIdeas } from './reindexing'

import './css/loading.css'
import moment from 'moment'

const LoadData = ({updateParentLoading, userEmail, forceReset}) => {

    const {updateData} = useContext(DataContext);

    const mountedRef = useRef(true);

    const [loading, updateLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            updateLoading(true);

            //Do requests
            let [
                entries,
                projects,
                users,
                ideas,
                efforts,
                landing,
                downloads,
                config,
            ] = await Promise.all([
                axios(`${FirebaseConfig.databaseURL}/entries.json`),
                axios(`${FirebaseConfig.databaseURL}/projects.json`),
                axios(`${FirebaseConfig.databaseURL}/users.json`),
                axios(`${FirebaseConfig.databaseURL}/ideas.json`),
                axios(`${FirebaseConfig.databaseURL}/efforts.json`),
                axios(`${FirebaseConfig.databaseURL}/landing.json`),
                axios(`${FirebaseConfig.databaseURL}/downloads.json`),
                axios(`${FirebaseConfig.databaseURL}/config.json`),
            ]);

            //Things related to the landing page
            landing = landing.data;

            config = config.data;

            users = Object.keys(users.data)
                .map(userId => {return {...users.data[userId], id:userId}}) //convert into array

            //check to see if the raw user we were passed is an admin
            const userAdmin = users.findIndex(user => user.email === userEmail && user.access === "admin") !== -1;

            //Convert into array with ID property and sort by timestamp
            entries = Object.keys(entries.data)
                .map(entryId => {return {...entries.data[entryId], id:entryId}}) //convert into array
                .sort((a, b) => b.timestamp - a.timestamp) //sort by timestamp

            if(!userAdmin) {
                entries = entries
                    .filter(entry => {
                        const keep = (entry.isTrashed === undefined || !entry.isTrashed) //remove trashed entries
                        //if(!keep) console.log("Filtering trashed entry", entry);
                        return keep;
                    })
            }

            if(!efforts.data) efforts = [];
            else {
                //Convert into array with ID property and sort by startDate
                efforts = Object.keys(efforts.data)
                    .map(effortId => {return {...efforts.data[effortId], id:effortId}})
                    .sort((a, b) => b.startDate - a.startDate);
            }

            if(!downloads.data) downloads = [];
            else {
                //Convert into array with ID property and sort by timestamp
                downloads = Object.keys(downloads.data)
                .map(downloadId => {return {...downloads.data[downloadId], id:downloadId}})
                .sort((a, b) => b.timestamp - a.timestamp);
            }
            if(!userAdmin) {
                downloads = downloads
                    .filter(download => {
                        const keep = (download.isTrashed === undefined || !download.isTrashed) //remove trashed downloads
                        //if(!keep) console.log("Filtering trashed entry", entry);
                        return keep;
                    })
            }

            //Convert into array with ID property
            projects = Object.keys(projects.data)
                .map(projectId => {return {...projects.data[projectId], id:projectId}}) //convert into array

            //Initialise entries, efforts and downloads
            projects = projects.map(project => {
                return {
                    ...project,
                    entries: project.entries || [],
                    efforts: project.efforts || [],
                    downloads: project.downloads || []
                }
            });

            if(!userAdmin) {
                //Remove empty and trashed projects
                projects = projects
                    .filter(project => {
                        const keep = !WeeklogUtils.isProjectEmpty(project);
                        //if(!keep) console.log("Filtering empty project", project);
                        return keep;
                    })
                    .filter(project => {
                        const keep = (project.isTrashed === undefined || !project.isTrashed) //remove trashed projects
                        //if(!keep) console.log("Filtering trashed project", project);
                        return keep;
                    })
            }

            ideas = Object.keys(ideas.data)
                .map(ideaId => {return {...ideas.data[ideaId], id:ideaId}}) //convert into array

            //remove entries that have no corresponding project - this probably means that their project was trashed
            entries = entries.filter(entry => {
                const keep = projects.findIndex(project => project.id === entry.project) !== -1;
                //if(!keep) console.log("Filtering entry with no project", entry);
                return keep;
            })

            //remove efforts that have no corresponding project - this probably means that their project was trashed
            efforts = efforts.filter(effort => {
                const keep = projects.findIndex(project => project.id === effort.project) !== -1;
                //if(!keep) console.log("Filtering entry with no project", entry);
                return keep;
            })

            //Turn object of entry IDs into array of IDs and Indexes
            projects = projects.map(project => {
                return {...project, entries:Object.keys(project.entries).map(entryId => {
                    return {id:entryId, index:entries.findIndex(entry => entry.id === entryId)}
                }).filter(entry => {
                    const keep = entry.index !== -1 //Filter out entries which weren't found - they were probably trashed
                    //if(!keep) console.log("Filtering entry out of project which had no index", entry);
                    return keep;
                })
            }})

            //Turn object of effort IDs into array of IDs and Indexes
            projects = projects.map(project => {
                return {...project, efforts:Object.keys(project.efforts).map(effortId => {
                    return {id:effortId, index:efforts.findIndex(effort => effort.id === effortId)}
                }).filter(effort => {
                    const keep = effort.index !== -1 //Filter out entries which weren't found - they were probably trashed
                    //if(!keep) console.log("Filtering entry out of project which had no index", entry);
                    return keep;
                })
            }})

            //Turn object of download IDs into array of IDs and Indexes
            projects = projects.map(project => {
                return {...project, downloads:Object.keys(project.downloads).map(downloadId => {
                    return {id:downloadId, index:downloads.findIndex(download => download.id === downloadId)}
                }).filter(download => {
                    const keep = download.index !== -1 //Filter out downloads which weren't found - they were probably trashed
                    //if(!keep) console.log("Filtering download out of project which had no index", entry);
                    return keep;
                }).sort((a, b) => {
                    return downloads[b.index].timestamp - downloads[a.index].timestamp;
                })
            }})

            //Sort entry and effort arrays within each project, then sort entire array by timestamp of their most recent (first) entry
            projects = projects
            .map(project => {
                return {...project, entries:project.entries.sort((a, b) => {
                    return entries.find(entry => entry.id === b.id).timestamp - entries.find(entry => entry.id === a.id).timestamp;
                })}
            })
            .map(project => {
                return {...project, efforts:project.efforts.sort((a, b) => {
                    return efforts.find(effort => effort.id === b.id).startDate - efforts.find(effort => effort.id === a.id).startDate;
                })}
            }).sort((a, b) => {
                const bValid = b.entries.length > 0;
                const aValid = a.entries.length > 0;
                if(!aValid && !bValid) return 0;
                if(!aValid) return 1;
                if(!bValid) return -1;

                return entries[b.entries[0].index].timestamp - entries[a.entries[0].index].timestamp;
            })

            //Map idea IDs to idea objects on Projects
            projects = projects.map(project => {
                return {...project, idea:{id: project.idea, index: ideas.findIndex(idea => idea.id === project.idea)}}
            })

            //Map project IDs to project objects on Ideas
            //Remove those projects which aren't currently in projects array (they were filtered out)
            ideas = ideas.map(idea => {
                if(!idea.projects) return {...idea, projects: []};

                return {...idea, projects:Object.keys(idea.projects).map(projectId => {
                    return {id:projectId, index:projects.findIndex(project => project.id === projectId)}
                }).filter(project => project.index !== -1)}
            })

            //Add ID and index of project to each idea, entry, effort and download item
            ideas = reindexIdeas(ideas, projects);
            entries = reindexResource(entries, projects);
            efforts = reindexResource(efforts, projects);
            downloads = reindexResource(downloads, projects);

            //Sort efforts by the date of their last activity (startDate, endDate or most recent entry date)
            efforts = efforts.sort((a, b) => {
                let latestA = a.endDate ? a.endDate : a.startDate;
                const entriesA = WeeklogUtils.getEffortEntries(a, projects[a.project.index].entries);
                if(entriesA && entriesA.length > 0) latestA = Math.max(latestA, entriesA[0].timestamp);

                let latestB = b.endDate ? b.endDate : b.startDate;
                const entriesB = WeeklogUtils.getEffortEntries(b, projects[b.project.index].entries);
                if(entriesB && entriesB.length > 0) latestB = Math.max(latestB, entriesB[0].timestamp);

                return latestB - latestA;
            })

            //Redo project effort indices after sorting efforts above
            projects = projects.map(project => reindexProject(project, ideas, entries, efforts, downloads));

            //update all information necessary to obscure secret, hidden and top-secret projects
            //unless we're logged in as an admin, the master admin who can see all!
            if(!userAdmin) {
                ({ projects, ideas, entries, efforts, downloads } = obscureSecrets(projects, ideas, entries, efforts, downloads));
            }

            //Now, create tags array from all unique tags across all projects
            const tags = projects.reduce((acc, project) => {
                return project.tags && project.tags.length > 0
                    ? acc.concat(project.tags.filter(t => acc.findIndex(aT => aT === t) === -1))
                    : acc
            }, []);

            //Check to see if entry projects contain the entry
            let errorCountA = 0;
            let errorOutputA = "Entry,ItsProject,ProjectsThatContainIt";
            entries.forEach(entry => {
                const entryProject = projects[entry.project.index];
                if(!entryProject.entries || entryProject.entries.length === 0 || entryProject.entries.findIndex(e => e.id === entry.id) === -1) {
                    const projectsThatContainEntry = projects
                        .filter(project => project.entries && project.entries.length > 0 && project.entries.findIndex(e => e.id === entry.id) !== -1)
                        .map(project => project.name);
                    errorOutputA += `\n${entry.summary}, ${entryProject.name}, ${projectsThatContainEntry.length === 0 ? "NONE" : projectsThatContainEntry.join(",")}`
                    errorCountA++;
                }
            })
            if(errorCountA > 0) {
                console.log("Linking error in database (Type A)!! Dumping log:");
                console.log(errorOutputA);
            }

            //Check to see if project entries refer to the right project
            let errorCountB = 0;
            let errorOutputB = "Project,EntryItShouldNotContain";
            projects.forEach(project => {
                project.entries.forEach(entry => {
                    entry = entries[entry.index];
                    if(entry.project.id !== project.id) {
                        errorOutputB += `\n${project.name}, ${entry.summary}`;
                        errorCountB++;
                    }
                })
            })
            if(errorCountB > 0) {
                console.log("Linking error in database (Type B)!! Dumping log:");
                console.log(errorOutputB);
            }

            //Check to see if project ideas contain the project
            projects.forEach(project => {
                const projectIdea = ideas[project.idea.index];
                if(!projectIdea.projects || projectIdea.projects.length === 0 || projectIdea.projects.findIndex(p => p.id === project.id) === -1) {
                    console.log("Project's idea's project list doesn't contain the project in question!", {name:project.name, id:project.id}, {name:projectIdea.name, id:projectIdea.id, projects:projectIdea.projects});
                }
            })

            //Check to see if idea projects refer to the right idea
            ideas.forEach(idea => {
                if(!idea.projects || idea.projects.length === 0) return;
                idea.projects.forEach(project => {
                    project = projects[project.index];
                    if(project.idea.id !== idea.id) {
                        console.log("Ideas's project doesn't refer to the idea in question!", {name:idea.name, id:idea.id}, {name:project.name, id:project.id, ideaId:project.idea.id});
                    }
                })
            })

            let perWeekData = {};
            entries.forEach(e => {
                const weeklogWeek = WeeklogUtils.getWeeklogWeek(moment(e.timestamp));
                //const yearPrettyString = weeklogWeek.year + " " + weeklogWeek.prettyString;
                const yearPrettyString = `${weeklogWeek.year} ${weeklogWeek.month}`;
                if(!perWeekData[yearPrettyString]) perWeekData[yearPrettyString] = {entries: [], projects: []};

                perWeekData[yearPrettyString].entries.push(e);
                const projectId = e.project.id;
                if(!perWeekData[yearPrettyString].projects.includes(projectId)) perWeekData[yearPrettyString].projects.push(projectId);
            });
            Object.keys(perWeekData).forEach(k => perWeekData[k].projects = perWeekData[k].projects.map(id => projects.find(p => p.id === id)));

            updateData({entries, projects, users, ideas, efforts, landing, tags, downloads, config, perWeekData});
            if(mountedRef.current) {
                updateLoading(false);
            }
        }
        fetchData();
    }, [updateData, userEmail, forceReset]);

    useEffect(() => {
        if(!mountedRef.current) return;
        updateParentLoading(loading ? "loading" : "has-data");
    }, [loading, updateParentLoading]);

    //don't do any state updates if unmounted
    useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, [])

    if(!loading) return null;

    return (
        <div className="container">
            <div className="loading-container" style={{height: (window.innerHeight - 64) + "px"}}>
                <div className="loading-inner">
                    <div className="loading-spinner"></div>
                    <p>WeekLog Engine<br/>Version {WeeklogUtils.version}</p>
                </div>
            </div>
        </div>
    )
}

export default LoadData;
