import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt, useHistory} from 'react-router-dom'

import {Formik, FieldArray} from 'formik';
import {Form, Autocomplete, Datepicker, Input, Textarea, Radio, Button} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import ReactMarkdown from 'react-markdown'

import AddImageItem from '../common/AddImageItem'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const AddEntry = () => {

    const {data, forceUpdateData} = useContext(DataContext);
    const [suggestions, setSuggestions] = useState({});
    const [finalEntry, setFinalEntry] = useState(null);
    const [isShowingPreview, setIsShowingPreview] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setSuggestions(data.projects.map(project => project.name));
    }, [data.projects]);

    const handleSubmit = (values, setSubmitting, reloadAfterSubmit) => {
        const doSubmitEntry = async(data, callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const addEntry = async () => {
                    const newRef = await axios.post(`${FirebaseConfig.databaseURL}/entries.json?auth=${idToken}`, data);
                    await axios.patch(`${FirebaseConfig.databaseURL}/projects/${data.project}/entries.json?auth=${idToken}`, {[newRef.data.name] : true})
                    setFinalEntry(newRef.data.name);
                    if(reloadAfterSubmit) forceUpdateData();
                    else history.push("/entries/add");
                }
                
                addEntry();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);

        const finalTimestamp = moment(values.date).startOf("day")
            .add(moment().hour(), "hours")
            .add(moment().minute(), "minutes")
            .add(moment().second(), "seconds").valueOf();

        const projectIndex = data.projects.findIndex(project => project.name === values.project);
        if(projectIndex === -1) {
            console.log("Invalid project, somehow?");
            setSubmitting(false);
            return;
        }


        const newEntryData = {
            summary: values.summary,
            label: values.label,
            slug: WeeklogUtils.getUniqueSlug(data, values.summary, data.projects[projectIndex]),
            project: data.projects[projectIndex].id,
            timestamp: finalTimestamp,
            content: values.content,
            images: values.images.map(image => {
                if(image.caption === "") return {
                    ref: image.ref,
                    name: image.name,
                    size: image.size,
                    type: image.type,
                    url: image.url,
                    caption: ""
                };
                else return {
                    ref: image.ref,
                    name: image.name,
                    size: image.size,
                    type: image.type,
                    url: image.url,
                    caption: image.caption
                };
            })
        }

        doSubmitEntry(newEntryData, data.projects[projectIndex].id, () => setSubmitting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            summary: yup.string().required("A summary is required"),
            date: yup.date().when('project', (project, schema) => {
                project = data.projects.find(p => p.name === project);
                if(!project || !project.entries || project.entries.length === 0) return schema;
                return schema.test('date', "That project already has an entry for the selected day.", val => {
                    return project.entries.reduce((acc, entry) => {
                        return acc && moment(data.entries[entry.index].timestamp, "x").startOf("day").valueOf() !== moment(val).startOf("day").valueOf();
                    }, true)
                });
            }).required("A date is required"),
            project: yup.string().required("A project is required").oneOf(data.projects.map(project => project.name), "Invalid project"),
        });
    }

    if(finalEntry !== null) {
        return <Redirect to={`${process.env.PUBLIC_URL}/entries`} />
    }

    return (
        <div className="container">
            <h1>Add Entry</h1>
            <Formik
                initialValues={{
                    summary: "",
                    label: "note",
                    date: moment().startOf("day").add(12, "hour").toDate(), //to prevent dirty from constantly changing
                    project: "",
                    content: "",
                    images: [],
                }}
                validationSchema={getSchema}
                onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
            >{({values, dirty, isSubmitting, setFieldValue, setSubmitting}) => (
                <Form>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Autocomplete name="project" label="Project" suggestions={suggestions} autoComplete="off" />
                    <Input name="summary" label="Summary" autoComplete="off" />
                    <div className={"option-" + values.label}>
                        <Radio inline name="label" label={`Label (${
                                values.label === "note" 
                                    ? "Note/Misc" : 
                                    values.label.substr(0, 1).toUpperCase() + values.label.substr(1)
                            })`} options={[
                                {value: "note", label: "📝"},
                                {value: "elaboration", label: "🎨"},
                                {value: "experimentation", label: "🧪"}
                            ]}/>
                    </div>
                    <Datepicker name="date" label="Date" />
                    <div className="content-previewable-wrapper">
                        <div className="content-previewable-label">
                            <span>Content</span>
                            <button  type="button" onClick={() => setIsShowingPreview(!isShowingPreview)}>{isShowingPreview ? "Hide Preview" : "Show Preview"}</button>
                        </div>
                        {isShowingPreview ? <div className="content-preview">
                            <ReactMarkdown source={values.content} />
                            <div className="content-image-previews">
                                {values.images.map(image => {
                                    return image && image.url && image.url !== "" 
                                        ? <img key={image.ref} src={image.url} alt={image.name} /> 
                                        : null
                                })}
                            </div>
                        </div> : <Textarea name="content" label="" autoComplete="off" /> }
                    </div>
                    <FieldArray name="images" render={arrayHelpers => { return (
                        <div className="form-element images">
                            <div className="images-wrapper">
                                <span className="label">Images</span>
                                <div className="images-container">
                                {values.images && values.images.length > 0 ? (
                                    values.images.map((image, index) => { return (
                                        <AddImageItem key={"image-" + index} imageArrayName="images" index={index} arrayHelpers={arrayHelpers} values={values} setFieldValue={setFieldValue} />
                                    )})
                                ) : (
                                    null
                                )}
                                <div className="add-image-container">
                                    <button type="button" onClick={() => arrayHelpers.push({url: "", caption: ""})}>Add an Image</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    )}} />
                    <div className="form-element">
                        <div className="buttons-wrapper">   
                            <Button 
                                disabled={isSubmitting && values.images.filter(image => image.uploadStatus !== "complete").length !== 0} 
                                children={isSubmitting ? "Submitting..." : "Submit"} 
                                type={"button"}
                                onClick={() => {
                                    handleSubmit(values, setSubmitting, true);
                                }}
                            />
                            <Button 
                                disabled={isSubmitting && values.images.filter(image => image.uploadStatus !== "complete").length !== 0} 
                                children={isSubmitting ? "Submitting..." : "Submit and add another"} 
                                type={"button"}
                                onClick={() => {
                                    handleSubmit(values, setSubmitting, false);
                                }}
                            />
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default AddEntry;