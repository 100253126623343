import React, {useState, useContext, useEffect} from 'react'
import {Redirect, Prompt} from 'react-router-dom'

import {Formik} from 'formik';
import {Form, Autocomplete, Datepicker, SubmitBtn, Input, Select} from 'react-formik-ui'
import moment from 'moment';
import * as yup from 'yup'

import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import AddTags from '../common/AddTags'
import AddHeroImage from '../common/AddHeroImage'

import WeeklogUtils from '../../shared/WeeklogUtils'
import DataContext from '../../DataContext'
import UserContext from '../../UserContext'

import FirebaseConfig from'../../FirebaseConfig'

import '../../css/form.css'

const statusOptions = [
    {label: "Concept", value: "concept"},
    {label: "Release", value: "release"},
    {label: "Experiment", value: "experiment"},
    {label: "Prerelease", value: "prerelease"},
    {label: "Prototype", value: "prototype"},
    {label: "Experimentation", value: "experimentation"},
    {label: "Stabilization", value: "stabilization"},
    {label: "Preparation", value: "preparation"},
    {label: "Elaboration", value: "elaboration"},
    {label: "Creation", value: "creation"},
];

const visibilityOptions = [
    {label: "Open", value: "open"},
    {label: "Secret", value: "secret"},
    {label: "Top-Secret", value: "top-Secret"},
    {label: "Hidden", value: "hidden"},
];

const AddProject = () => {

    const {user} = useContext(UserContext);
    const {data, forceUpdateData} = useContext(DataContext);
    const [suggestions, setSuggestions] = useState({});
    const [finalProject, setFinalProject] = useState(null);
    const [advancedOpen, setAdvancedOpen] = useState(false);

    useEffect(() => {
        setSuggestions(data.ideas.map(idea => idea.name));
    }, [data.ideas]);

    const handleSubmit = (values, {setSubmitting}) => {

        const ideaIndex = data.ideas.findIndex(idea => idea.name === values.idea);
        if(ideaIndex === -1) {
            if(values.idea === "") values.idea = values.name;
            if(!window.confirm(`Idea entered does not exist. If you proceed, a new Idea will be created with the name "${values.idea}"`)) return;
        }

        const doSubmitProject = async(data, ideaData, callback) => {
            firebase.auth().currentUser.getIdToken(true).then(idToken => {
                const addProject = async () => {
                    if(ideaData) {
                        //First, add the new Idea and add its ref to the new project data
                        const newIdea = await axios.post(`${FirebaseConfig.databaseURL}/ideas.json?auth=${idToken}`, ideaData);
                        data.idea = newIdea.data.name;
                    }

                    //Then, add the new project
                    const newRef = await axios.post(`${FirebaseConfig.databaseURL}/projects.json?auth=${idToken}`, data);

                    //And add its ref to its idea, whether it was just created or not
                    await axios.patch(`${FirebaseConfig.databaseURL}/ideas/${data.idea}/projects.json?auth=${idToken}`, {[newRef.data.name] : true})
                    setFinalProject(newRef.data.name);
                    forceUpdateData();
                }

                addProject();
            }).catch(err => {
                console.log("Error submitting", err);
                callback();
            })
        }

        setSubmitting(true);

        const finalTimestamp = moment(values.date).startOf("day")
            .add(moment().hour(), "hours")
            .add(moment().minute(), "minutes")
            .add(moment().second(), "seconds").valueOf();

        const heroImage = values.heroImage && values.heroImage.url ? values.heroImage : {
            ref: "placeholder.png",
            name: "placeholder.png",
            size: 3655,
            type: "image/png",
            url: "https://firebasestorage.googleapis.com/v0/b/lscom-weeklog.appspot.com/o/placeholder.png?alt=media&token=75c8b1ab-f62d-41a2-867c-88c154a66cc0",
        };
        const newProjectData = {
            name: values.name,
            slug: WeeklogUtils.getUniqueProjectSlug(data, values.name),
            description: values.description,
            idea: ideaIndex === -1 ? null : data.ideas[ideaIndex].id,
            status: values.status,
            version: values.version,
            timestamp: finalTimestamp,
            visibility: values.visibility,
            content: "",
            featuredImages: false,
            heroImage,
            isTrashed: false,
            transitions: false,
            tags: values.tags.map(tag => tag.name)
        }

        const newIdeaData = ideaIndex !== -1 ? null : {
            name: values.name,
            slug: WeeklogUtils.getUniqueIdeaSlug(data, values.name),
            timestamp: finalTimestamp
        }

        doSubmitProject(newProjectData, newIdeaData, () => setSubmitting(false));
    }

    const getSchema = () => {
        return yup.object().shape({
            name: yup.string().required("A name is required"),
            description: yup.string().required("A description is required"),
            idea: yup.string().notRequired(),
            status: yup.string().required("A status is required").oneOf(statusOptions.map(option => option.value), "Invalid status"),
            version: yup.string().required("A version is required - set as 0.0.0 if you don't know what it should be"),
            date: yup.date().required("A date is required"),
            visibility: yup.string().required("A visibility is required").oneOf(visibilityOptions.map(option => option.value), "Invalid visibility"),
            heroImage: yup.object().notRequired(),
        });
    }

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />
    if(finalProject !== null) {
        return <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />
    }

    return (
        <div className="container">
            <h1>Add Project</h1>
            <Formik
                initialValues={{
                    name: "",
                    description: "",
                    idea: "",
                    status: "concept",
                    version: "0.0.0",
                    date: moment().startOf("day").add(12, "hour").toDate(), //to prevent dirty from constantly changing
                    visibility: "open",
                    heroImage: {},
                    tags: [],
                    tagEntry: ""
                }}
                validationSchema={getSchema}
                onSubmit={handleSubmit}
            >{({dirty, isSubmitting, values, setFieldValue, errors, touched}) => (
                <Form>
                    <Prompt when={dirty} message="You have unsaved changes. If you leave now, those changes will be lost." />
                    <Input name="name" label="Name" autoComplete="off" />
                    <Input name="description" label="Description" autoComplete="off" />
                    <Autocomplete name="idea" label="Idea" suggestions={suggestions} autoComplete="off" />
                    <AddTags values={values} validTags={data.tags} />
                    <AddHeroImage fieldName="heroImage" value={values.heroImage} setFieldValue={setFieldValue} error={{touched: touched["heroImage"], error: errors.heroImage}} />
                    <div className="form-element">
                        <div className="expand-wrapper">
                            <span>Advanced</span>
                            <span className="project-group-expander" onClick={() => setAdvancedOpen(!advancedOpen)}>{advancedOpen ? "▼" : "▶"}</span>
                        </div>
                    </div>
                    <div className={advancedOpen ? null : "hidden"}>
                        <Select name="status" label="Status" options={statusOptions} />
                        <Input name="version" label="Version" autoComplete="off" />
                        <Datepicker name="date" label="Date" />
                        <Select name="visibility" label="Visibility" options={visibilityOptions} />
                    </div>
                    <div className="form-element">
                        <div className="buttons-wrapper">
                            <SubmitBtn disabled={isSubmitting} children={isSubmitting ? "Submitting..." : "Submit"} />
                        </div>
                    </div>
                </Form>
            )}</Formik>
        </div>
    )
}

export default AddProject;
