import React, {useEffect, useState, useContext, useRef} from 'react'

import moment from 'moment'

import DataContext from '../../DataContext'

import TimelineCalcs from './TimelineCalcs'
import TimelineProject from './TimelineProject'
import TimelineMonthMarker from './TimelineMonthMarker'

import "./css/timeline.css"
import WeeklogUtils from '../../shared/WeeklogUtils'

const Timeline = () => {
    const [timeBounds, updateTimeBounds] = useState({min: moment().valueOf(), max: 0});
    const [hoveringEntry, updateHoveringEntry] = useState({entry: null, pos: {x:0, y: 0}});
    const [monthMarkers, updateMonthMarkers] = useState([]);
    const [timelineProjects, updateTimelineProjects] = useState([]);
    const [dimensions, updateDimensions] = useState({height:window.innerHeight, width:window.innerWidth});
    const hoverPanel = useRef(0);

    const {data} = useContext(DataContext);

    useEffect(() => {
        document.title = "WeekLog - Timeline";
    }, []);

    useEffect(() => {
        if(!data.entries || data.entries.length === 0) return;

        updateTimeBounds(TimelineCalcs.globalTimeBounds(data.entries));
    }, [data.entries]);

    useEffect(() => {
        const months = TimelineCalcs.months(timeBounds.min, timeBounds.max);
        updateMonthMarkers(months.map(month =>
            <TimelineMonthMarker
                key={month.valueOf()}
                month={month}
                globalTimeBounds={timeBounds}
            />
        ));
    }, [timeBounds])

    useEffect(() => {
        if(!hoveringEntry.entry) {
            hoverPanel.current.style.setProperty("display", "none");
        } else {
            hoverPanel.current.style.setProperty("display", "block");
            hoverPanel.current.style.setProperty("top", (hoveringEntry.pos.y + 10 + window.pageYOffset) + "px");
            if(hoveringEntry.pos.x < window.innerWidth * 0.5) {
                hoverPanel.current.style.removeProperty("right");
                hoverPanel.current.style.setProperty("left", (hoveringEntry.pos.x + 10) + "px");
            } else {
                hoverPanel.current.style.removeProperty("left");
                hoverPanel.current.style.setProperty("right", (window.innerWidth - hoveringEntry.pos.x + 10) + "px");
            }
        }
    }, [hoveringEntry])

    useEffect(() => {
        function handleResize() {
            updateDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)

        return _ => window.removeEventListener('resize', handleResize)
    });

    useEffect(() => {
        const validProjects = data.projects.filter(project => project.entries && project.entries.length > 0 && !project.isTrashed);
        updateTimelineProjects(validProjects
            .map(project => WeeklogUtils.getPopulatedProject(project, data))
            .reverse().map((project, index) => {
                return (
                    <TimelineProject
                        key={project.id}
                        project={project}
                        index={index / validProjects.length}
                        width={100 / validProjects.length}
                        globalTimeBounds={timeBounds}
                        onHoverOn={updateHoveringEntry}
                        onHoverOff={() => updateHoveringEntry(h => { return {...h, entry:null} })}/>
                )
            }));
    }, [dimensions, timeBounds, data])



    return (
        <div className="container timeline-container" style={{
            height: `${TimelineCalcs.getHeight(timeBounds)}px`
        }}>
            <div ref={hoverPanel} className="timeline-hover-panel">
                <h3>{hoveringEntry.entry ? hoveringEntry.entry.summary : ""}</h3>
                <h4>{hoveringEntry.entry ? data.projects[hoveringEntry.entry.project.index].name : ""}</h4>
                <p>{hoveringEntry.entry ? moment(hoveringEntry.entry.timestamp, "x").format("Do MMMM YYYY") : ""}</p>
            </div>
            {monthMarkers}
            {timelineProjects}
        </div>
    )
}

export default Timeline;
