import React, {useContext, useEffect, useState} from 'react'
import {Redirect, Link} from 'react-router-dom'

import UserContext from '../../UserContext'
import DataContext from '../../DataContext'

import DashboardProjectTable from './DashboardProjectTable'

import WeeklogUtils from '../../shared/WeeklogUtils'
import './css/dashboard.css'

const Dashboard = () => {
    const {user} = useContext(UserContext);
    const {data} = useContext(DataContext);

    const [activeProjects, setActiveProjects] = useState([]);
    const [inactiveProjects, setInactiveProjects] = useState([]);
    const [trashedProjects, setTrashedProjects] = useState([]);

    useEffect(() => {
        document.title = "WeekLog - Dashboard";
    }, []);

    useEffect(() => {
        const allProjects = data.projects
        .filter(project => project.isTrashed !== true)
        .map(project => WeeklogUtils.getPopulatedProject(project, data));
        setActiveProjects(allProjects.filter(project => WeeklogUtils.isStatusActive(project.status)));
        setInactiveProjects(allProjects.filter(project => !WeeklogUtils.isStatusActive(project.status)));
        setTrashedProjects(data.projects
            .filter(project => project.isTrashed === true)
            .map(project => WeeklogUtils.getPopulatedProject(project, data)));
    }, [data])

    if(!user) return <Redirect to={`${process.env.PUBLIC_URL}/`} />

    return (
        <div className="container dashboard">
            <header>
                <div>
                    <h1>Dashboard</h1>
                    <h4>Logged in as {user.username}</h4>
                </div>
                <div>
                    <Link to={`${process.env.PUBLIC_URL}/entries/add`} className="hover-tile">Add Entry</Link>
                    <Link to={`${process.env.PUBLIC_URL}/projects/add`} className="hover-tile">Add Project</Link>
                    <Link to={`${process.env.PUBLIC_URL}/logout`} className="hover-tile">Logout</Link>
                </div>
            </header>
            
            {!activeProjects || activeProjects.length === 0 ? null : (
                <div>
                    <h2>Active Projects</h2>
                    <DashboardProjectTable projects={activeProjects} />
                </div>
            )}

            {!inactiveProjects || inactiveProjects.length === 0 ? null : (
                <div>
                    <h2>{activeProjects && activeProjects.length > 0 ? "Inactive " : ""}Projects</h2>
                    <DashboardProjectTable projects={inactiveProjects} />
                </div>
            )}

            {!trashedProjects || trashedProjects.length === 0 ? null : (
                <div>
                    <h2>Trashed Projects</h2>
                    <DashboardProjectTable projects={trashedProjects} />
                </div>
            )}
        </div>
    )
}

export default Dashboard;