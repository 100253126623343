import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import ReactPlayer from 'react-player'
import LazyImage from '../common/LazyImage'

const ProjectDetailGallery = ({project}) => {

    const [galleryItems, setGalleryItems] = useState([]);

    useEffect(() => {
        if(!project || ((!project.entries || project.entries.length === 0) && (!project.featuredImages || project.featuredImages.length === 0))) {
            setGalleryItems([]);
            return;
        }

        const fullImageList = (!project.featuredImages ? [] : project.featuredImages)
                .concat(project.entries
                .filter(e => e && e.images && e.images.length > 0 && !e.isTrashed)
                .map(e => e.images.map(i => {return {...i, entrySlug:e.slug}}))
                .reduce((acc, images) => images && images.length > 0 ? acc.concat(images) : acc, [])
            );

        setGalleryItems(fullImageList.map((image, index) => {
            const extension = image.url.split(".").slice(-1)[0].split("?")[0];
            return (
                <div key={index} className="project-gallery-content-wrapper">
                    <Link 
                        to={image.entrySlug 
                            ? `${process.env.PUBLIC_URL}/entries/${image.entrySlug}`
                            : `${process.env.PUBLIC_URL}/projects/${project.slug}`
                        } 
                        className="project-gallery-content">
                        {(image.type === "video/mp4" || extension === "mp4")
                            ? <ReactPlayer 
                                    url={image.url}
                                    playing={true}
                                    loop={true}
                                    volume={0}
                                    muted={true}
                                    controls={false}
                                    playsinline={true}
                                    width={"100%"}
                                    height={"100%"}
                                />
                            : <LazyImage src={image.url} alt={image.caption || "Entry image"} />
                        }
                    </Link>
                </div>
            )
        }));

    }, [project])

    return (
        <div className="project-detail-gallery">
            {galleryItems}
        </div>
    )
}

export default ProjectDetailGallery;