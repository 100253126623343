import React, {useContext, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'

import Particles from 'react-particles-js';

import DataContext from '../../DataContext'

import SelectedProject from './SelectedProject'
import RecentEntry from './RecentEntry'
import CurrentProject from './CurrentProject'

import WeeklogUtils from '../../shared/WeeklogUtils'
import './css/landing.css'

const Landing = () => {

    const {data} = useContext(DataContext);
    const [selectedProjects, setSelectedProjects] = useState([]);

    useEffect(() => {
        setSelectedProjects(data.landing.featuredProjects
            .map(slug => data.projects.find(p => p.slug === slug))
            .map(project => WeeklogUtils.getPopulatedProject(project, data))
            .map(p => !p ? null : <SelectedProject key={p.id} project={p} />))

        document.title = data.config.htmlTitle || "WeekLog";
    }, [data])

    const [currentProjects, setCurrentProjects] = useState([]);
    useEffect(() => {
        setCurrentProjects(data.projects
            .map(p => WeeklogUtils.getPopulatedProject(p, data))
            .filter(p => p.entries && p.entries.length)
            .filter(p => p.visibility !== "hidden")
            .sort((a, b) => b.entries[0].date - a.entries[0].date)
            .slice(0, data.landing.currentFocusCount)
            .map(project => <CurrentProject key={project.id} project={project} />)
        );
        console.log(data.projects);
    }, [data, data.projects, data.entries, data.landing.currentFocusCount]);

    const [recentEntries, setRecentEntries] = useState([]);
    useEffect(() => {
        setRecentEntries(
            data.entries.slice(0, data.landing.currentFocusCount)
                .filter(entry => entry.isTrashed !== true)
                .map(entry => { return {...entry, project:data.projects[entry.project.index]}})
                .map(entry => <RecentEntry key={entry.id} entry={entry} />)
        );
    }, [data.entries, data.projects, data.landing.currentFocusCount])

    const [titleSpans, setTitleSpans] = useState([]);
    useEffect(() => {
        setTitleSpans(data.landing.title.spans.map((text, index) => <span key={index}>{text}</span>));
    }, [data.landing.title.spans])

    const [titleIntro, setTitleIntro] = useState([]);
    useEffect(() => {
        setTitleIntro(data.landing.title.intro.map((text, index) => <p key={index}>{text}</p>));
    }, [data.landing.title.intro]);

    const [aboutContent, setAboutContent] = useState([]);
    useEffect(() => {
        setAboutContent(data.landing.about.content.map((text, index) => <p key={index}>{text}</p>));
    }, [data.landing.about.content]);

    if(!data.landing) return;

    return (
        <div className="container landing">
            <div className="landing-top">
                <Particles width="100vw" height="300vh" params={{
                    particles: {
                        color: {
                            value: [
                                "#BFB662",
                                "#D26A83",
                                "#B294E6",
                                "#78B6BE",
                                "#6FC072",
                            ],
                        },
                        opacity: {
                            value: 0.2,
                            animation: {
                                speed: 0.1
                            }
                        },
                        links: {
                            enable: false
                        },
                        shadow: {
                            enable: true,
                            offset: {x: 2, y: 2},
                            blur: 5,
                            color: {
                                value: "rgba(0,0,0,0.5)"
                            }
                        },
                        size: {
                            random: {
                                enable: true,
                                minimumValue: 3
                            },
                            value: 6
                        },
                        move: {
                            speed: 1
                        },
                        number: {
                            value: 100
                        }
                    }
                }}/>
                <div className="landing-intro" style={{
                    backgroundImage: `url(${data.landing.title.image.url})`
                }}>
                    <h1>{titleSpans}</h1>
                    {titleIntro}
                </div>
            </div>
            <div className="landing-selected">
                <h1>Selected Works</h1>
                <div className="selected-grid">
                    {selectedProjects}
                </div>
                <Link className="see-all hover-tile" to={`${process.env.PUBLIC_URL}/projects/`}>See All Projects</Link>
            </div>
            <div className="landing-current">
                <h1>Current Focus</h1>
                <div className="landing-current-sidebyside">
                    <div>
                        <h2>Active Projects</h2>
                        {currentProjects && currentProjects.length > 0
                        ? currentProjects
                        : <p>I'm not currently working on anything!</p>}
                    </div>
                    <div>
                        <h2>Recent Entries</h2>
                        {recentEntries && recentEntries.length > 0
                        ? recentEntries
                        : <p>I haven't posted any entries yet!</p>}
                    </div>
                </div>
            </div>
            <div className="landing-about">
                <h1>About Me</h1>
                <div className="landing-about-sidebyside">
                    <div>
                        {aboutContent}
                    </div>
                    <div>
                        <img src={data.landing.about.image.url} alt={data.landing.about.image.alt}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing;
