import React, {useState, useEffect, useContext} from 'react'

import moment from 'moment'

import DataContext from '../../DataContext'
import WeeklogUtils from '../../shared/WeeklogUtils'

import EntryWeek from './EntryWeek'

const Entries = () => {
    const [earliestTS, updateEarliestTS] = useState(() => moment().startOf('week').subtract(7, 'day').valueOf());
    const [weekEntries, updateWeekEntries] = useState([]);

    const {data} = useContext(DataContext);

    useEffect(() => {
        let m = moment("01/11/2020", "DD/MM/YYYY");
        for(let i = 0; i < 100; i++) {
            console.log(`${m.format("DD/MM/YYYY")} - W${m.isoWeek()} ${m.isoWeekYear()}`);
            m = m.add(1, "days");
        }
        document.title = "WeekLog - Entries"
    }, [])

    const showAnotherWeek = () => {
        const newEarliest = moment(earliestTS, "x").subtract(7, 'day').valueOf();
        updateEarliestTS(newEarliest);
    }

    //on first load, get entry data and update the initial timestamp
    useEffect(() => {
        updateEarliestTS(moment().startOf('week').subtract(7, 'day').valueOf());
    }, []);

    //I don't understand why adding the empty dependencies for this hook causes the onscroll event to get caused a billion times...
    useEffect(() => {
        const onScroll = () => {
            if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 100) {
                const newEarliest = moment(earliestTS, "x").subtract(7, 'day').valueOf();
                updateEarliestTS(newEarliest);
            }
        }
            
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    });

    useEffect(() => {
        let weekList = {};
        data.entries
            .filter(entry => { return entry.timestamp > earliestTS && !entry.isTrashed})
            .forEach(entry => {
                const week = WeeklogUtils.getWeeklogWeek(moment(entry.timestamp));
                const weekKey = week.year + "_" + week.month + "_" + week.week;
                if(!weekList[weekKey]) weekList[weekKey] = [entry];
                else weekList[weekKey] = [...weekList[weekKey], entry];
            });

        //if no entries were found in the last seven days, start iterating backwards through the weeks until we find some entries
        if(Object.keys(weekList).length === 0 && data.entries.length > 0) {
            let activeEarliest = earliestTS;
            let attempts = 0;
            while(Object.keys(weekList).length === 0 && attempts < 100) {
                activeEarliest = moment(activeEarliest, "x").subtract(7, 'day').valueOf();
                data.entries
                    // eslint-disable-next-line no-loop-func
                    .filter(entry => { return entry.timestamp > activeEarliest && !entry.isTrashed})
                    .forEach(entry => {
                        const week = WeeklogUtils.getWeeklogWeek(moment(entry.timestamp));
                        const weekKey = week.year + "_" + week.month + "_" + week.week;
                        if(!weekList[weekKey]) weekList[weekKey] = [entry];
                        else weekList[weekKey] = [...weekList[weekKey], entry];
                    });
                attempts++;
            }
            updateEarliestTS(activeEarliest);
        }

        updateWeekEntries(Object.keys(weekList).sort((a, b) => { return b - a }).map(key => {
            return <EntryWeek key={key} entries={weekList[key]} />
        }));
    }, [earliestTS, data.entries]);

    return (
        <div className="container">
            {weekEntries}
            {document.body.offsetHeight - 100 > window.innerHeight ? null : ( //show more button if there's no scrolling to trigger loads
                <div className="more-entries-container">
                    <button className="more-entries-button hover-tile" onClick={showAnotherWeek}>Show More Entries</button>
                </div>
            )}
        </div>
    )
}

export default Entries;